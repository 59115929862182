import { type EnumerationModel } from './enumerationModel';
import { type Address } from './address';
import { type CustomFieldType } from '@clover/custom-fields/models/custom-field';

export interface Company {
  id: number;
  name: string;
  logoUrl?: string;
  trustScore?: number;
  twoFactorEnabled?: boolean;
  connectionStatus?: ConnectionStatus;
  licenseTier?: CompanyLicenseTier;
  fullAddress?: string;

  privateData?: {
    contacts: any[];
    locations: any[];
    sections: CompanyData[];
  };
  publicData?: {
    address: Address;
    annualRevenue: EnumerationModel;
    businessType: EnumerationModel;
    companyType: EnumerationModel;
    description: string;
    legalType: EnumerationModel;
    numberOfEmployeesType: EnumerationModel;
    productCategories: {
      segments: EnumerationModel[];
      families: EnumerationModel[];
      classes: EnumerationModel[];
      categories: EnumerationModel[];
    };
    relatedCompanies: any[];
    subIndustries: {
      sectors: EnumerationModel[];
      industryGroups: EnumerationModel[];
      industries: EnumerationModel[];
      subIndustries: EnumerationModel[];
    };
    websiteUrl: string;
    tags: any[];
    tickerSymbol: string;
    yearStarted: number;
    diversityStatus: EnumerationModel;
  };
  confidentialData?: {
    sections: CompanyData[];
  };
  proprietaryData?: {
    sections: CompanyData[];
  };
  companyAliasName?: string;
  companyAliasId?: string;
  isDeferred?: boolean;

  [x: string]: any;
}

export interface CompanySearchModel {
  id: number;
  name: string;
  logoUrl: string;
  connectionStatus: ConnectionStatus;
  description: string;
  address: Address;
  industries: any[];
  industryGroups: any[];
  numberOfEmployeesType: EnumerationModel;
  sectors: string[];
  subIndustries: string[];
  tags: any[];
  fullAddress?: string;
  companyType: EnumerationModel;
  subsectors?: any[];
  tradingPartnerName: string;
  tradingPartnerId: string;
  isDeferred?: boolean;
  isApproved?: boolean;
  associatedCompany?: Company;
}

export interface CompanyData {
  id: string;
  title: string;
  fields: CompanyDataField[];
  accessType: CompanyDataAccessType;
}

export interface CompanyDataField {
  fieldId: number;
  value: any;
  helpText?: string;
  title?: string;
  type: CustomFieldType;
}

export enum CompanyDataAccessType {
  Internal = 'Internal',
  External = 'External',
}

export enum ConnectionStatus {
  Invited = 'Invited',
  Connected = 'Connected',
  NotConnected = 'NotConnected',
}

export enum CompanyLicenseTier {
  System = 'System',
  Business = 'Business',
  Individual = 'Individual',
  Enterprise = 'Enterprise',
}
